<template>
  <ModalBase
    v-show="showModal"
    name="confirm"
    :title="title"
    :allowClose="false"
    :allowFullscreen="allowFullscreen"
    :mobileAlignBottom="mobileAlignBottom"
    :bodyPadding="bodyPadding"
  >
    <template #body>
      <slot>
        Confirm you wish to do this, there is no undo
      </slot>
    </template>

    <template #footer>
      <div class="flex justify-between">
        <UtilButton @click="cancel()" color="secondary">{{ cancelButtonLabel }}</UtilButton>
        <UtilButton color="primary" @click="confirm()">{{ confirmButtonLabel }}</UtilButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/modals/ModalBase'
import UtilButton from '@/components/utils/UtilButton'

export default {
  name: 'ModalConfirm',
  components: {
    ModalBase,
    UtilButton
  },
  props: {
    title: {
      type: String,
      default: 'Are you sure?'
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel'
    },
    confirmButtonLabel: {
      type: String,
      default: 'Confirm'
    },
    allowClose: Boolean,
    allowFullscreen: {
      type: Boolean,
      default: true
    },
    bodyPadding: {
      type: Boolean,
      default: true
    },
    mobileAlignBottom: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showModal: false,
      resolve: null,
      reject: null
    }
  },
  methods: {
    open() {
      this.showModal = true

      this.$store.dispatch('modal/handleModalOpen', `Modal${this.$options.name}`)

      // Return promise to calling parent so it knows when user accepts or cancels
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    confirm() {
      this.resolve(true)
      this.closeModal()
    },

    cancel() {
      this.resolve(false)
      this.closeModal()
    },

    closeModal() {
      this.showModal = false
      this.$store.dispatch('modal/handleModalClose', `Modal${this.$options.name}`)
    }
  }
}
</script>
